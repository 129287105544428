//================================== GLOBAL ========================================

body {
  background-color: transparent !important;
}

p {
  margin: 0;
}

//================================== MIXINS ========================================

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-ms-keyframes #{$animationName} {
    @content;
  }

  @-o-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

//================================== KEYFRAMES ========================================

@include keyframes(Jump) {
  0% {
    bottom: 0px;
  }
  20% {
    bottom: 5px;
  }
  40% {
    bottom: 0px;
  }
}

@include keyframes(Pulse) {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.25, 1.25, 1.25);
    transform: scale3d(1.25, 1.25, 1.25);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@include keyframes(FadeIn) {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@include keyframes(FadeOut) {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@include keyframes(ZoomIn) {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@include keyframes(ZoomOut) {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

@include keyframes(ZoomInOut) {
  from {
    -webkit-transform: scale3d(0.1, 0.1, 0.1);
    transform: scale3d(0.1, 0.1, 0.1);
  }

  50% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
  }

  to {
    -webkit-transform: scale3d(0.1, 0.1, 0.1);
    transform: scale3d(0.1, 0.1, 0.1);
  }
}

@include keyframes(SlideLeft) {
  from {
    opacity: 0;
    transform: translateX(40px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

//================================== CLASS NAMES ========================================

.HoverableWrapper {
  .WrapperHoverHidden {
    display: none;
  }
  &:hover {
    .WrapperHoverHidden {
      display: block;
    }
  }
}

.Animated {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.Jump {
  @include animation((Jump 1.5s infinite));
}

.Pulse {
  @include animation((Pulse 1.5s));
}

.FadeIn {
  @include animation(FadeIn 500ms);
}

.FadeOut {
  @include animation(FadeOut 500ms);
}

.ZoomIn {
  @include animation(ZoomIn 500ms);
}

.ZoomOut {
  @include animation(ZoomOut 500ms);
}

.ZoomInOut {
  @include animation(ZoomInOut 1200ms);
}

.SlideLeft {
  @include animation(SlideLeft 1000ms);
}

.scroll-visibility {
  --scroll-display: none;
  ::-webkit-scrollbar {
    opacity: 0;
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    opacity: 0;
  }

  ::-webkit-scrollbar-thumb {
    display: var(--scroll-display);
    background-color: grey;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
}
.prevent-inheritance > * {
  --scroll-display: none;
}
